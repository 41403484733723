import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Fab } from '@material-ui/core';
import Profile from '../components/Profile';
import Stats from '../components/Stats';
import Skills from '../components/Skills';
import { TopAnchor } from '../components/CustomStyled';
import dungeonService from '../services/dungeonService';
import Weapons from '../components/Weapons';
import Inventory from '../components/Inventory';
import SpellBook from './SpellBook';
import { same, calculateModifier } from '../services/helper';
import CharacterAdmin from '../components/CharacterAdmin';
// import Feats from '../components/Feats';
import DeathSavingThrows from '../components/DeathSavingThrows';

const CharacterSheet = ({ characterData }) => {
  const [characterBase, updateCharacterBase] = useState(characterData);
  const [character, updateCharacter] = useState(characterData);
  const [isDirty, setDirty] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [authorized, setAuthorized] = useState(false);
  const [classInfo, setClassInfo] = useState({});
  // const [raceInfo, setRaceInfo] = useState({});
  const { stats } = character.info;
  function update(charUpdates) {
    setDirty(!same(characterBase, charUpdates));
    updateCharacter(charUpdates);
  }

  async function save() {
    setDirty(false);
    setEditMode(false);
    const success = await dungeonService.saveCharacter(character);
    if (!success) setDirty(true);
    else updateCharacterBase(character);
  }

  function cancel() {
    setDirty(false);
    setEditMode(false);
    updateCharacter(characterBase);
  }

  async function getClassInfo(className) {
    if (!className) return;
    const result = await dungeonService.getClass(className);
    setClassInfo(result);
  }

  // async function getRaceInfo(race) {
  //   if (!race) return;
  //   const result = await dungeonService.getRace(race.id);
  //   setRaceInfo(result);
  // }

  useEffect(() => {
    updateCharacter(characterData);
    setAuthorized(characterData.authorized);
    getClassInfo(characterData.class.id);
    // getRaceInfo(characterData.race);
    setEditMode(false);
  }, [characterData]);
  console.log(character);
  return (
    <SheetContainer>
      { authorized && !editMode && (
        <TopAnchor>
          { !isDirty ? (
            <Fab
              color="secondary"
              size="small"
              onClick={() => setEditMode(true)}
            >
              <i className="material-icons">edit</i>
            </Fab>
          ) : (
              <SaveBar>
                <Fab color="secondary" size="small" style={{ marginRight: '1rem' }} onClick={cancel}>
                  <i className="material-icons">clear</i>
                </Fab>
                <Fab color="secondary" size="small" onClick={save}>
                  <i className="material-icons">save</i>
                </Fab>
              </SaveBar>
          ) }
        </TopAnchor>
      ) }
      { editMode && (
        <>
          <SaveBar>
            <Fab color="secondary" size="small" style={{ marginRight: '1rem' }} onClick={cancel}>
              <i className="material-icons">clear</i>
            </Fab>
            <div />
            <Fab color="secondary" size="small" onClick={save}>
              <i className="material-icons">save</i>
            </Fab>
          </SaveBar>
          <Admin>
            <CharacterAdmin character={character} update={update} />
          </Admin>
        </>
      ) }
      <ProfileArea>
        <Profile
          character={character}
          hitDice={classInfo.hitDice || ''}
          update={update}
          disabled={!authorized}
          editing={editMode}
        />
        { character.hp <= 0
          ? <DeathSavingThrows id={character.id} />
          // Switching to removeItem, clear was clearing the entire storage
          : localStorage.removeItem(`deathsaves_${character.id}`)
        }
      </ProfileArea>
      <StatsArea>
        <Stats
          character={character}
          saves={classInfo.prof_saving_throws || ''}
          update={update}
          disabled={!authorized || !editMode}
        />
      </StatsArea>
      <SkillsArea>
        <Skills character={character} editing={editMode} update={update} />
      </SkillsArea>
      <WeaponsArea>
        <Weapons
          disabled={!authorized}
          proWeapons={classInfo.proWeapon || ''}
          weaponList={character.weapons || []}
          weaponIDs={character.info.weaponIDs}
          dex={stats.dex}
          str={stats.str}
          proBonus={character.level.proBonus}
          update={weaponIDs => update({ ...character, info: { ...character.info, weaponIDs } })}
        />
      </WeaponsArea>
      <EquipmentArea>
        <Inventory
          disabled={!authorized}
          itemList={character.inventory || []}
          gold={character.info.gold}
          update={(gold, inventory) =>
            update({ ...character, info: { ...character.info, gold }, inventory })
          }
        />
      </EquipmentArea>
      <Misc>

        {/* <Feats
          disabled={!authorized}
          traits={raceInfo.traits || []}
          feats={character.features || []}
          // featIDs={character.features || []}
          update={feats => update({ ...character, feats })}
        /> */}
        { classInfo && classInfo.spellcasting_ability && (
          <SpellBook
            disabled={!authorized}
            classInfo={classInfo}
            level={character.level}
            spells={character.spells || []}
            update={spells => update({ ...character, spells })}
            mod={calculateModifier(
              character[
                classInfo.spellcasting_ability.toLowerCase().substring(0, 3)
              ], character.proBonus,
            )}
          />
        ) }
      </Misc>
    </SheetContainer>
  );
};

export default CharacterSheet;

CharacterSheet.propTypes = {
  characterData: PropTypes.shape({
    name: PropTypes.string,
    race: PropTypes.string,
    class: PropTypes.string,
  }).isRequired,
};

const SheetContainer = styled.div`
    position: relative;
    display: grid;
    grid-gap: 0.625em;
    grid-template-columns: 18.75em minmax(auto, 15.625em) minmax(auto, 12.5em);
    grid-template-areas:
      "admin admin admin"
      "pro pro pro"
      "skill stat stat"
      "skill wpn wpn"
      "skill eqp eqp"
      "etc etc etc";
  
  @media screen and (max-width: 36em) {
        display: flex;
      flex-direction: column;
    }
  `;

const Admin = styled.div`
    border-top:'1rem';
    grid-area: admin;
    display: flex;
    flex-direction: column;
  `;

const SaveBar = styled.div`
  position:fixed;
  display:flex;
  flex-direction: row;
  z-index:1;
  `;
const ProfileArea = styled.div`
    grid-area: pro;
  `;
const StatsArea = styled.div`
    grid-area: stat;
  `;
const SkillsArea = styled.div`
    grid-area: skill;
  `;
const WeaponsArea = styled.div`
    grid-area: wpn;
  `;
const EquipmentArea = styled.div`
    grid-area: eqp;
  `;
const Misc = styled.div`
    grid-area: etc;
    display: flex;
    flex-direction: column;
  `;
